export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.hook("app:error", (error, instance, info) => {
		const reoload_error_list = [
			"error loading dynamically imported module",
			"Importing a module script failed",
			"Failed to fetch dynamically imported module",
		];
		for (const message of reoload_error_list) {
			if (error.message.indexOf(message) > -1) {
				window.location.reload();
			}
		}
	});
});