<template>
    <Navbar />
    <div class="page">
        <NuxtLoadingIndicator color="#38a169" :height="3" />
        <NuxtPage />
        <Transition name="popup">
            <component :is="popup" v-if="popup" />
        </Transition>
        <footer class="footer">
            <FooterFAQ />
            <FooterLinks />
            <FooterInfo />
        </footer>
    </div>
    <LazyGoUp />
    <notifications position="bottom right" :dangerously-set-inner-html="true" animation-type="css" width="auto" />
</template>

<script setup>
import { notify } from "@kyvg/vue3-notification";

const { popup, setPopup } = usePopup();

watch(
    () => useRoute().path,
    (route) => {
	    if (!import.meta.client) return;

	    if (!/\/vote$/.test(route)) {
		    setPopup(null);
	    }

	    const toastCookie = useCookie("toast");
	    if (!toastCookie.value) return;

        const { type, title } = toastCookie.value;
        notify({ type, title });
        toastCookie.value = null;

        if (
            type === "error" &&
            (title.includes("login") || title.includes("logged"))
        ) {
            const LoginPopup = defineAsyncComponent(() => import("~/components/popups/LoginPopup.vue"));
            setPopup(LoginPopup);
        }
    }
);

const meta = {
    title: "Minecraft Server List - Find Minecraft Multiplayer Servers",
    ogTitle: "Minecraft Server List - Find Minecraft Multiplayer Servers",
    keywords: "minecraft ip list, minecraft server list, minecraft servers, minecraft ip addresses, minecraft server ip addresses list, minecraft multiplayer",
    description: "Find minecraft multiplayer servers IP/address on our Minecraft server list. Search for your favourite type of multiplayer server here.",
    ogDescription: "Find minecraft multiplayer servers IP/address on our Minecraft server list. Search for your favourite type of multiplayer server here.",
    lang: "en",
};

useServerSeoMeta(meta);
useSeoMeta(meta);

useServerHead({
    link: [
        {
            rel: "canonical",
            href: "https://wwww.minecraftiplist.com" + useRoute().path,
        },
    ],
    htmlAttrs: {
        lang: "en",
    },
});
</script>

<style>
body {
    font-family: "Inter", sans-serif;
    background: #fefefe;
}

.vue-notification-group {
    bottom: 1em !important;
    right: 1em !important;

    min-width: 326px;
    max-width: 600px;
}

.vue-notification-wrapper {
    overflow: auto !important;
}

.vue-notification {
    font-size: 1rem;

    min-height: 64px;
    max-height: 800px;

    display: flex !important;
    align-items: center;

    border: 0;
    border-radius: 8px;

    padding: 1em !important;

    margin: .5em 0 0 0 !important;

    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);

    justify-content: space-between;

    &::after {
        content: "x";
        font-family: monospace;
        font-size: 1.25rem;
        cursor: pointer;
    }

    .notification-title {
        display: flex;
        align-items: center;
        max-width: 90%;
    }

    .notification-title::before {
        content: "";
        filter: brightness(0) invert(1);
        display: block;

        background-repeat: no-repeat;

        margin: auto 18px auto 0px;
        outline: none;
        border: none;
        padding: 0;
        transition: 0.3s ease;
        align-items: center;
        width: 32px;
        height: 32px;
    }

    &.success {
        background: #4caf50;
        color: #fff;

        .notification-title::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGVjay1jaXJjbGUiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWNoZWNrLWNpcmNsZSBmYS13LTE2IFZ1ZS1Ub2FzdGlmaWNhdGlvbl9faWNvbiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNNTA0IDI1NmMwIDEzNi45NjctMTExLjAzMyAyNDgtMjQ4IDI0OFM4IDM5Mi45NjcgOCAyNTYgMTE5LjAzMyA4IDI1NiA4czI0OCAxMTEuMDMzIDI0OCAyNDh6TTIyNy4zMTQgMzg3LjMxNGwxODQtMTg0YzYuMjQ4LTYuMjQ4IDYuMjQ4LTE2LjM3OSAwLTIyLjYyN2wtMjIuNjI3LTIyLjYyN2MtNi4yNDgtNi4yNDktMTYuMzc5LTYuMjQ5LTIyLjYyOCAwTDIxNiAzMDguMTE4bC03MC4wNTktNzAuMDU5Yy02LjI0OC02LjI0OC0xNi4zNzktNi4yNDgtMjIuNjI4IDBsLTIyLjYyNyAyMi42MjdjLTYuMjQ4IDYuMjQ4LTYuMjQ4IDE2LjM3OSAwIDIyLjYyN2wxMDQgMTA0YzYuMjQ5IDYuMjQ5IDE2LjM3OSA2LjI0OSAyMi42MjguMDAxeiI+PC9wYXRoPjwvc3ZnPg==");
        }
    }

    &.warn {
        background: #ffc107;
        color: #fff;

        .notification-title::before {
            background-image: url("data:image/svg+xml;base64,ICA8c3ZnCiAgICBhcmlhLWhpZGRlbj0idHJ1ZSIKICAgIGZvY3VzYWJsZT0iZmFsc2UiCiAgICByb2xlPSJpbWciCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICB2aWV3Qm94PSIwIDAgNTEyIDUxMiIKICA+CiAgICA8cGF0aAogICAgICBmaWxsPSJjdXJyZW50Q29sb3IiCiAgICAgIGQ9Ik01MDQgMjU2YzAgMTM2Ljk5Ny0xMTEuMDQzIDI0OC0yNDggMjQ4UzggMzkyLjk5NyA4IDI1NkM4IDExOS4wODMgMTE5LjA0MyA4IDI1NiA4czI0OCAxMTEuMDgzIDI0OCAyNDh6bS0yNDggNTBjLTI1LjQwNSAwLTQ2IDIwLjU5NS00NiA0NnMyMC41OTUgNDYgNDYgNDYgNDYtMjAuNTk1IDQ2LTQ2LTIwLjU5NS00Ni00Ni00NnptLTQzLjY3My0xNjUuMzQ2bDcuNDE4IDEzNmMuMzQ3IDYuMzY0IDUuNjA5IDExLjM0NiAxMS45ODIgMTEuMzQ2aDQ4LjU0NmM2LjM3MyAwIDExLjYzNS00Ljk4MiAxMS45ODItMTEuMzQ2bDcuNDE4LTEzNmMuMzc1LTYuODc0LTUuMDk4LTEyLjY1NC0xMS45ODItMTIuNjU0aC02My4zODNjLTYuODg0IDAtMTIuMzU2IDUuNzgtMTEuOTgxIDEyLjY1NHoiCiAgICA+PC9wYXRoPgogIDwvc3ZnPg==");
        }
    }

    &.info {
        background: #2196f3;
        color: #fff;

        .notification-title::before {
            background-image: url("data:image/svg+xml;base64,ICA8c3ZnCiAgICBhcmlhLWhpZGRlbj0idHJ1ZSIKICAgIGZvY3VzYWJsZT0iZmFsc2UiCiAgICByb2xlPSJpbWciCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICB2aWV3Qm94PSIwIDAgNTEyIDUxMiIKICA+CiAgICA8cGF0aAogICAgICBmaWxsPSJjdXJyZW50Q29sb3IiCiAgICAgIGQ9Ik0yNTYgOEMxMTkuMDQzIDggOCAxMTkuMDgzIDggMjU2YzAgMTM2Ljk5NyAxMTEuMDQzIDI0OCAyNDggMjQ4czI0OC0xMTEuMDAzIDI0OC0yNDhDNTA0IDExOS4wODMgMzkyLjk1NyA4IDI1NiA4em0wIDExMGMyMy4xOTYgMCA0MiAxOC44MDQgNDIgNDJzLTE4LjgwNCA0Mi00MiA0Mi00Mi0xOC44MDQtNDItNDIgMTguODA0LTQyIDQyLTQyem01NiAyNTRjMCA2LjYyNy01LjM3MyAxMi0xMiAxMmgtODhjLTYuNjI3IDAtMTItNS4zNzMtMTItMTJ2LTI0YzAtNi42MjcgNS4zNzMtMTIgMTItMTJoMTJ2LTY0aC0xMmMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmg2NGM2LjYyNyAwIDEyIDUuMzczIDEyIDEydjEwMGgxMmM2LjYyNyAwIDEyIDUuMzczIDEyIDEydjI0eiIKICAgID48L3BhdGg+CiAgPC9zdmc+Cg==");
        }
    }

    &.error {
        background: #ff5252;
        color: #fff;

        .notification-title::before {
            background-image: url("data:image/svg+xml;base64,ICA8c3ZnCiAgICBhcmlhLWhpZGRlbj0idHJ1ZSIKICAgIGZvY3VzYWJsZT0iZmFsc2UiCiAgICByb2xlPSJpbWciCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICB2aWV3Qm94PSIwIDAgNTc2IDUxMiIKICA+CiAgICA8cGF0aAogICAgICBmaWxsPSJjdXJyZW50Q29sb3IiCiAgICAgIGQ9Ik01NjkuNTE3IDQ0MC4wMTNDNTg3Ljk3NSA0NzIuMDA3IDU2NC44MDYgNTEyIDUyNy45NCA1MTJINDguMDU0Yy0zNi45MzcgMC01OS45OTktNDAuMDU1LTQxLjU3Ny03MS45ODdMMjQ2LjQyMyAyMy45ODVjMTguNDY3LTMyLjAwOSA2NC43Mi0zMS45NTEgODMuMTU0IDBsMjM5Ljk0IDQxNi4wMjh6TTI4OCAzNTRjLTI1LjQwNSAwLTQ2IDIwLjU5NS00NiA0NnMyMC41OTUgNDYgNDYgNDYgNDYtMjAuNTk1IDQ2LTQ2LTIwLjU5NS00Ni00Ni00NnptLTQzLjY3My0xNjUuMzQ2bDcuNDE4IDEzNmMuMzQ3IDYuMzY0IDUuNjA5IDExLjM0NiAxMS45ODIgMTEuMzQ2aDQ4LjU0NmM2LjM3MyAwIDExLjYzNS00Ljk4MiAxMS45ODItMTEuMzQ2bDcuNDE4LTEzNmMuMzc1LTYuODc0LTUuMDk4LTEyLjY1NC0xMS45ODItMTIuNjU0aC02My4zODNjLTYuODg0IDAtMTIuMzU2IDUuNzgtMTEuOTgxIDEyLjY1NHoiCiAgICA+PC9wYXRoPgogIDwvc3ZnPgo=");

            width: 32px;
            height: 28px;
        }
    }
}

.is-correct {
    position: absolute;
    width: 16px;
    height: 16px;

    top: 50%;
    transform: translateY(-50%);
    right: 0.5em;
    transition: none;
}

.is-correct--true {
    background: url("~/assets/icons/tick.svg") no-repeat center;
    background-size: 16px;
    filter: invert(80%) sepia(46%) saturate(5893%) hue-rotate(74deg) brightness(103%) contrast(78%);
}

.is-correct--false {
    position: absolute;
    width: 16px;
    height: 16px;

    background: url("~/assets/icons/cross.svg") no-repeat center;
    background-size: 20px;
    filter: invert(25%) sepia(89%) saturate(6900%) hue-rotate(356deg) brightness(99%) contrast(118%);
}
</style>

<style scoped>
.popup-enter-active,
.popup-leave-active {
    transition: opacity 0.35s ease;
}

.popup-enter-from,
.popup-leave-to {
    opacity: 0;
}

.footer {
    margin-top: 5em;
    margin-bottom: 2em;
}
</style>