export function usePopup() {
    const popup = useState('popup', () => shallowRef(null));
    
    function setPopup(content) {
        popup.value = content;
    }

    return {
        popup,
        setPopup,
    };
}
