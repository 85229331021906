import {titleEncode} from "~/utils/helpers.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
    console.log("[REQ]", to.path + (to.query ? "?" + JSON.stringify(to.query) : ""));
    if (to.path.startsWith("/search/")) {
        let param = to.path.split("/");
        if (param.length > 2) {
            param = param[2];
            if (param.length > 0) {
                let tags = await $fetch("/api/tag/search", {
                    query: {
                        search: param,
                        exact: true,
                    },
                });

                if (tags.length > 0) {

                    return navigateTo("/server-tags/" + tags[0].urlname);
                }
            }
        }
    } else if (to.path.startsWith("/index")) {
        const query = to.query;

        if (query.action === 'vote') {
            const response = await $fetch('/api/server/' + query.listingID);

            return navigateTo(`/server/${titleEncode(response.title, response.custom_title_url)}-${query.listingID}/vote`, {
                replace: true,
                redirectCode: 301
            });
        }

        return navigateTo("/");
    } else {
        const loggedIn = useCookie("loggedIn");

        if (to.path.startsWith("/password-reset") || to.path.startsWith("/login")) {
            if (loggedIn.value && !from.query.redirect) {
                return navigateTo("/");
            }
        } else if (
            to.path.startsWith("/server/") &&
            (to.path.endsWith("/edit") ||
                to.path.endsWith("/favourite") ||
                to.path.endsWith("/my") ||
                to.path.endsWith("/add"))
        ) {
            if (!loggedIn.value) {
                return navigateTo("/login?redirect=" + to.path);
            }
        }
    }
});
