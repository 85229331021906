<template>
    <div v-if="tags && tags.length > 0" class="tags">
        <IndexTag v-for="loopTag in tags" :tag="loopTag"/>
    </div>
</template>

<script setup>
const route = useRoute();
const tag = route.params.tag;

const tags = shallowRef([]);

if (tag) {
    const {data} = await useFetch("/api/tag/similar", {
        query: {
            tag: tag,
        },
        ignoreResponseError: true,
        transform: (data) => {
            for (const fetchedTag in data.tags) {
                delete data.tags[fetchedTag].similar;
            }
            return data;
        }
    });

    if (data.value && data.value.tags) {
        tags.value = data.value.tags;
    }
}

if (!tags.value || tags.value.length === 0) {
    const {data} = await useFetch("/api/tag", {
        query: {
            limit: 25,
        },
        pick: ["popularTags"],
        ignoreResponseError: true
    });

    if (data.value && data.value.popularTags) {
        tags.value = data.value.popularTags;
    }
}
</script>

<style lang="scss">
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;

  /* center*/
  justify-content: center;
  align-content: flex-start;

  width: 100%;

  min-height: calc(3 * (0.25em + 0.5em + 0.5em + 16px));
}

@media (min-width: 1024px) {
  .tags {
    max-width: 90%;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
