import revive_payload_client_4sVQNw7RlN from "/webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/webapp/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/webapp/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_iGLjBbor9q from "/webapp/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import click_outside_oytHm5ka8v from "/webapp/plugins/click-outside.js";
import error_fix_JCNCiXnBVC from "/webapp/plugins/error-fix.js";
import fathom_client_6QHXGUB7wS from "/webapp/plugins/fathom.client.js";
import notifications_EFFJUsRga8 from "/webapp/plugins/notifications.js";
import user_client_c2sXTPisXU from "/webapp/plugins/user.client.js";
import vue_tippy_hWNFm8xa76 from "/webapp/plugins/vue-tippy.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_iGLjBbor9q,
  click_outside_oytHm5ka8v,
  error_fix_JCNCiXnBVC,
  fathom_client_6QHXGUB7wS,
  notifications_EFFJUsRga8,
  user_client_c2sXTPisXU,
  vue_tippy_hWNFm8xa76
]