<template>
    <div class="footer-info">
        <p>@{{ year }} minecraftiplist.com</p>
        <NuxtLink to="/terms-and-conditions">Terms and Conditions</NuxtLink>
    </div>
</template>

<script setup>
const year = new Date().getFullYear();
</script>

<style scoped lang="scss">
.footer-info {
  border-top: 3px solid #ccc;
  padding-top: 1em;

  * {
    color: #6a6a6a;
    font-size: 0.75rem;
  }

  a {
    text-decoration: none;
  }

  display: flex;
  gap: 0.5em;
  flex-direction: row;
  align-items: center;
}
</style>
