<template>
  <NuxtLink :key="tag.name" class="tag" :to="`/server-tags/${tag.urlname}`">
    {{ tag.name }}
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  tag: {
    type: Object,
  }
})

// Stupid helper to calculate text width
const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2796875,0.2765625,0.3546875,0.5546875,0.5546875,0.8890625,0.665625,0.190625,0.3328125,0.3328125,0.3890625,0.5828125,0.2765625,0.3328125,0.2765625,0.3015625,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.2765625,0.2765625,0.584375,0.5828125,0.584375,0.5546875,1.0140625,0.665625,0.665625,0.721875,0.721875,0.665625,0.609375,0.7765625,0.721875,0.2765625,0.5,0.665625,0.5546875,0.8328125,0.721875,0.7765625,0.665625,0.7765625,0.721875,0.665625,0.609375,0.721875,0.665625,0.94375,0.665625,0.665625,0.609375,0.2765625,0.3546875,0.2765625,0.4765625,0.5546875,0.3328125,0.5546875,0.5546875,0.5,0.5546875,0.5546875,0.2765625,0.5546875,0.5546875,0.221875,0.240625,0.5,0.221875,0.8328125,0.5546875,0.5546875,0.5546875,0.5546875,0.3328125,0.5,0.2765625,0.5546875,0.5,0.721875,0.5,0.5,0.5,0.3546875,0.259375,0.353125,0.5890625]
const avg = 0.5279276315789471

function measureText(str, fontSize) {
  return Array.from(str).reduce(
    (acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg), 0
  ) * fontSize
}

const tagWidth = measureText(props.tag.name, 22) + "px";

</script>

<style lang="scss">
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: v-bind(tagWidth);

  background-color: #e2e8f0;
  // padding: 0.25em 0.5em;
  font-size: 1.0em;
  border-radius: 6px;
  border: 0.5px solid #a0aec0;
  text-decoration: none;
  color: #222;
  min-height: 1.8em;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: #bac3d4;
  }
}

@media (max-width: 480px) {
  .tag {
    font-size: 0.9em;
  }
}
</style>
