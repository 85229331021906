<template>
    <NuxtLink no-prefetch active-class="active-page" to="/">
        <img alt="Home icon" src="~/assets/icons/home.svg" width="24" height="24">
        Home
    </NuxtLink>
    <NuxtLink no-prefetch active-class="active-page" to="/blog" external>
        <img alt="Tag icon" src="~/assets/icons/info.svg" width="24" height="24">
        Blog
    </NuxtLink>
    <button class="tagged-servers-button" @mouseenter="taggedServers = true" @mouseleave="closeTaggedServers">
        <img alt="Tagged Servers icon" src="~/assets/icons/tag.svg" width="24" height="24">
        Tagged Servers
    </button>
    <div v-if="!pending && data" class="tagged-servers" :class="{ active: taggedServers }" @mouseleave="closeTaggedServers">
        <div class="title">Popular Tags</div>
        <NuxtLink v-if="data.popularTags" v-for="tag in data.popularTags" :key="tag.name" no-prefetch :to="`/server-tags/${tag.urlname}`">
            {{ tag.name }}
        </NuxtLink>
        <div class="title">Random Tags</div>
        <NuxtLink v-if="data.popularTags" v-for="tag in data.randomTags" :key="tag.name" no-prefetch :to="`/server-tags/${tag.urlname}`">
            {{ tag.name }}
        </NuxtLink>
    </div>
    <NuxtLink no-prefetch to="https://skinsmc.org/" target="_blank">
        <img alt="Minecraft Skins shirt icon" src="~/assets/icons/tshirt.svg" width="24" height="24">
        Minecraft Skins
    </NuxtLink>
    <NuxtLink no-prefetch active-class="active-page" to="/contact">
        <img alt="Chat bubble icon" src="~/assets/icons/bubble.svg" width="24" height="24">
        Contact Us
    </NuxtLink>
</template>

<script setup>
const taggedServers = ref(false);

function closeTaggedServers() {
    // if mouse is in .tagged-servers, return
    setTimeout(() => {
        const hovered = document.querySelectorAll(":hover");
        for (const el of hovered) {
            if (
                el.classList.contains("tagged-servers") ||
                el.classList.contains("tagged-servers-button")
            ) {
                return;
            }
        }

        taggedServers.value = false;
    }, 300);
}

const {pending, data} = await useLazyFetch("/api/tag", {
    query: {
        limit: 20,
        random: true
    },
    server: false
});
</script>

<style scoped lang="scss">
.tagged-servers {
  position: absolute;
  z-index: 100;

  background-color: #edf2f7;
  top: 4.5em;
  left: 30%;
  width: calc(100vw - 70%);
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 0.35em;
  padding: 0.5em 1em 1em 1em;

  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  border-radius: 0px 0px 8px 8px;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .title {
    width: 100%;
  }

  .title:not(:first-child) {
    margin-top: 0.5em;
  }

  a {
    border-radius: 8px;
    padding: 6px 8px;
    font-size: 1rem;
    line-height: 1.0rem;
    height: 1rem;
    border: 0.5px solid #a0aec0;

    background-color: #e2e8f0;

    text-decoration: none;
    color: #222;

    &:hover {
      background-color: #bac3d4;
    }
  }
}
</style>