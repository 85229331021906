<template>
  <nav class="container">
    <NuxtLink no-prefetch class="logo box" to="/">
      <img alt="Logo of Minecraft IP List" src="~/assets/img/full_logo.svg" width="123" height="120">
    </NuxtLink>
    <div class="links box">
      <NavbarLinks />
    </div>
    <div class="socials">
      <NuxtLink no-prefetch to="https://discord.gg/npsBSDs2hX" target="_blank" external>
        <img alt="Link to Minecraft IP Facebook" src="~/assets/icons/discord.svg" class="discord-icon" width="24"
          height="24">
      </NuxtLink>
      <NuxtLink no-prefetch to="https://twitter.com/minecraftiplist" target="_blank" external>
        <img alt="Link to Minecraft IP Twitter" src="~/assets/icons/twitter.svg" width="20" height="20">
      </NuxtLink>
      <div class="login">
        <div v-if="user === undefined ? loggedInCookie : user" class="user" :class="userDropdown ? 'active' : ''">
          <button @click="toggleUserDropdown">
            {{ user ? user.username : usernameCookie }}
            <img alt="Arrow pointing to the right" src="~/assets/icons/right_arrow.svg" width="14" height="14">
          </button>
          <Transition>
            <div v-if="userDropdown" v-click-outside="closeNavDropdowns" class="hamburger-menu">
              <NuxtLink no-prefetch to="/server/add">Add Server</NuxtLink>
              <NuxtLink no-prefetch to="/server/my">My Servers</NuxtLink>
              <NuxtLink no-prefetch to="/server/favourite">Favourite Servers</NuxtLink>
              <button @click="userSettings">
                User Settings
              </button>
              <NuxtLink v-if="user && user.is_admin" to="/admin"> Admin panel</NuxtLink>
              <button @click="logout">Logout</button>
            </div>
          </transition>
        </div>
        <button v-else @click="login">
          Login
          <img alt="arrow pointing to the right" src="~/assets/icons/right_arrow.svg" width="14" height="14">
        </button>
      </div>
    </div>

    <div class="hamburger" @click="toggleHamburger">
      <span>
        <img v-if="!hamburgerState" src="~/assets/icons/hamburger.svg" alt="Hamburger">
        <svg v-else class="feather feather-x" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <line x1="18" x2="6" y1="6" y2="18" />
          <line x1="6" x2="18" y1="6" y2="18" />
        </svg>
      </span>
    </div>
    <Transition>
      <div v-if="hamburgerState" v-click-outside="closeNavDropdowns" class="hamburger-menu">
        <NuxtLink no-prefetch active-class="active-page" to="/blog" external>
          <img alt="Tag icon to represent blogs" src="~/assets/icons/info.svg">
          Blog
        </NuxtLink>
        <a class="split" @click="toggleTaggedServers">
          <span class="left">
            <img alt="Tagged Servers" src="~/assets/icons/tag.svg">
            Tagged Servers
          </span>
          <span class="right" :class="`${taggedServersState ? 'active' : ''}`">
            <img alt="Tagged Servers" src="~/assets/icons/arrow_down.svg">
          </span>
        </a>
        <div :style="{ display: `${taggedServersState ? 'block' : 'none'}` }" class="tagged-servers">
          <IndexTags />
        </div>
        <NuxtLink to="https://skinsmc.org/" target="_blank">
          <img alt="Minecraft Skins" src="~/assets/icons/tshirt.svg">
          Minecraft Skins
        </NuxtLink>
        <NuxtLink active-class="active-page" href="/contact">
          <img alt="Contact us" src="~/assets/icons/bubble.svg">
          Contact Us
        </NuxtLink>
        <div class="login">
          <div v-if="user === undefined ? loggedInCookie : user" class="user active">
            <button class="persist" @click="toggleUserDropdown">
              {{ user ? user.username : usernameCookie }}
              <img alt="Login" src="~/assets/icons/right_arrow.svg" width="14" height="14">
            </button>
            <div class="user_buttons">
              <NuxtLink to="/server/add">
                Add Server
              </NuxtLink>
              <NuxtLink to="/server/my">
                My Servers
              </NuxtLink>
              <NuxtLink to="/server/favourite">
                Favourite Servers
              </NuxtLink>
              <button @click="userSettings">
                User Settings
              </button>
              <NuxtLink v-if="user && user.is_admin" to="/admin"> Admin panel</NuxtLink>
              <button @click="logout">
                Logout
              </button>
            </div>
          </div>
          <button v-else @click="login">
            Login
            <img alt="Login" src="~/assets/icons/right_arrow.svg" width="14" height="14">
          </button>
        </div>
      </div>
    </Transition>
  </nav>
</template>

<script setup>
import { usePopup } from "~/composables/usePopup";
import { notify } from "@kyvg/vue3-notification";

const usernameCookie = useCookie("username");
const loggedInCookie = useCookie("loggedIn");

const hamburgerState = ref(false);
const taggedServersState = ref(false);
const userDropdown = ref(false);

const toggleHamburger = () => {
  hamburgerState.value = !hamburgerState.value;
};

const toggleTaggedServers = () => {
  taggedServersState.value = !taggedServersState.value;
};

const toggleUserDropdown = () => {
  userDropdown.value = !userDropdown.value;
};

function closeNavDropdowns() {
  hamburgerState.value = false;
  taggedServersState.value = false;
  userDropdown.value = false;
}

const { setPopup } = usePopup();

const login = () => {
  const LoginPopup = defineAsyncComponent(() => import("~/components/popups/LoginPopup.vue"));

  setPopup(LoginPopup);
};

const {
  user,
  logout: userLogout,
} = useUser();

const session = useCookie("session");
if (!session.value) {
  loggedInCookie.value = false;
  usernameCookie.value = null;

  useCookie("session", null);
  useCookie("username", null);
  useCookie("loggedIn", false);
}

const userSettings = () => {
  const UserSettingsPopup = defineAsyncComponent(() => import("~/components/popups/UserSettingsPopup.vue"));

  setPopup(UserSettingsPopup);
};

const logout = () => {
  userLogout();

  notify({
    type: "success",
    title: "Logged out successfully"
  })
};
</script>

<style lang="scss">
$grayBackground: #edf2f7;
$grayText: #4a5568;
$lightGreen: #38a169;

nav {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

nav>*:last-child {
  white-space: nowrap;
  text-align: right;
}

html,
body {
  margin: 0;
  padding: 0;
}

html,
body,
a,
button,
p,
span {
  font-weight: 500;
  font-family: "Inter";
}

* {
  transition: all 0.35s ease-in-out;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

nav {
  // display: flex;
  // justify-content: space-between;

  // position: relative;
  height: 2.5em;
  padding: 1em 2em;

  background-color: $grayBackground;

  .hamburger {
    display: none;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 44px;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.5vw;
    text-wrap: nowrap;

    &>a,
    &>button {
      background: none;
      border: 0;

      cursor: pointer;
      text-decoration: none;
      color: $grayText;
      font-size: 1.2rem;

      display: flex;
      align-items: center;
      gap: 0.25em;

      img {
        height: 1.25em;
      }
    }
  }

  .socials {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.65em;

    a {
      display: flex;
      align-items: center;

      width: 1.75em;
      height: 1.75em;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .discord-icon {
      transform: translateY(7%) scale(1.1)
    }
  }

  .login {
    display: flex;
    align-items: center;
    padding-left: 1vw;

    button {
      background: $lightGreen;
      border: 0;
      padding: 0.35em 0.7em;
      color: white;
      font-size: 1.2rem;
      border-radius: 6px;
      cursor: pointer;
      text-wrap: nowrap;
    }

    .user {
      position: relative;
      width: 100%;

      .hamburger-menu {
        position: absolute;
        width: calc(100% + 3em);
        padding: 0.5em 0;
        gap: 0.25em;

        a,
        button {
          width: 100%;
          display: flex;
          justify-content: center;
          border: 0;
          background: 0;
          font-size: 1rem;
          cursor: pointer;
          transform: none;
          padding: 0.4em 0;
          text-align: center;
          color: $grayText;

          &:hover,
          &:focus {
            padding: 0.4em 0;
            color: #38a169;
            background: #d6dee6;
          }
        }
      }

      .user_buttons {
        margin-top: 0.8em;
        width: 100%;

        a,
        button {
          width: 100%;
          background: none;
          color: $grayText;
          font-size: 1.05rem;
          padding: 0.33em 0 0.33em 0.25em;
        }
      }
    }
  }

  .links,
  .socials {

    &>a,
    &>button {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 50%;
        height: 2px;
        background-color: $lightGreen;
      }

      &:hover,
      &:focus {
        transform: scale(1.01);

        &:after {
          width: 100%;
          left: 0;
        }
      }

      &.active-page {
        color: $lightGreen;

        img {
          filter: invert(48%) sepia(54%) saturate(452%) hue-rotate(95deg) brightness(100%) contrast(92%);
        }
      }
    }
  }
}

.page {
  //display: flex;
  //flex-direction: column;
  //align-content: center;
  //flex-wrap: wrap;

  width: min(1200px, 100%);
  margin: 0 auto;
}

@media (max-width: 1420px) {
  nav .links {
    width: 60%;
    gap: 2vw;
  }
}

@media (max-width: 1200px) {
  .page {
    width: 95%;
  }

  nav {
    padding: 1em !important;

    .logo img {
      height: 80%;
    }

    .links>a,
    .login>button {
      font-size: 1rem;
    }

    .socials a {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

@media (max-width: 1024px) {
  nav {

    .links {
      gap: 1vw;

      &>a,
      &>button {
        font-size: 1rem;
      }
    }

    .socials a {
      padding: 12px;
      width: 1.5em;
      height: 1.5em;
    }

    .hamburger-menu {
      right: 15%;
    }
  }
}

@media (max-width: 1024px) {
  nav {
    justify-content: space-between;

    .links {
      display: none;

    }

    .socials>.login {
      display: none;
    }

    .login {
      margin-left: 0.5em;
      margin-bottom: 1em;

      .hamburger-menu a,
      .hamburger-menu button {
        color: $grayText;
      }
    }

    .hamburger {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img,
      svg {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .page {
    width: 95%;
  }
}

.hamburger-menu {
  z-index: 10;
  position: absolute;
  top: 2.7em;
  left: calc(15% - 3em);

  background: $grayBackground;

  display: flex;
  flex-direction: column;
  gap: 0.5em;

  a,
  button {
    padding: 0.25em 0.5em 0.35em 0.5em;
    text-decoration: none;

    display: flex;
    align-items: center;
    gap: 0.25em;

    color: $grayText;

    img {
      width: 1.5em;
      height: 1.5em;
    }

    &.active-page {
      &::before {
        opacity: 1;
      }
    }

    &::after {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: $lightGreen;
      opacity: 0;
    }
  }
}

@media (max-width: 1024px) {
  .hamburger-menu {
    width: 100%;
    left: 0;
    top: 4.5em;

    .split {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        gap: 0.25em;
      }

      .right {
        display: flex;
        align-items: center;
        margin-right: 1em;
        transform: rotate(-90deg);

        img {
          width: 1em;
          height: 1em;
        }

        &.active {
          transform: rotate(0deg);
        }
      }
    }

    .tagged-servers {
      margin: -0.5em 1em;

      .tags {
        justify-content: flex-start;

        .tag {
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}
</style>