<template>
    <div class="divider" />
    <div class="footer-links-row">
        <div class="footer-links-col">
            <h4>TOP MINECRAFT SERVERS</h4>
            <ul>
                <li>
                    <a href="/server/joinpurpleprison.netfreevipranktoday-5020">
                        Purple Prison Server IP
                    </a>
                </li>
                <li>
                    <a href="/server/BlocksMC-5481">BlocksMC Server IP</a>
                </li>
                <li>
                    <a href="/server/HypixelNetwork-2576">
                        Hypixel Server IP
                    </a>
                </li>
                <li>
                    <a href="/server/SchoolRP-5418">SchoolRP Server IP</a>
                </li>
                <li>
                    <a href="/server/CraftYourTown-5308">
                        CraftYourTown Server IP
                    </a>
                </li>
                <li>
                    <a href="/server/AdvanciusNetworkFREERANKS-5059">
                        Advancius Network Server IP
                    </a>
                </li>
                <li>
                    <a href="/server/Foreverplay-5067">
                        ForeverPlay Server IP
                    </a>
                </li>
                <li>
                    <a href="/server/Hoplite-27188">
                        Hoplite Server IP
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer-links-col">
            <h4>MINECRAFT SERVER LINKS</h4>
            <ul>
                <li>
                    <a href="/blog" :external="true">
                        Minecraft Blog
                    </a>
                </li>
                <li>
                    <a href="https://www.minecraft.net/en-us" target="_blank">
                        Minecraft Official Website
                    </a>
                </li>
                <li>
                    <a href="https://www.minecraft.net/en-us/servers" target="_blank">
                        Official Minecraft Server Guide
                    </a>
                </li>
                <li>
                    <a href="https://en.wikipedia.org/wiki/Minecraft" target="_blank">
                        Minecraft Wikipedia
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=0ZZqlYFW1v0" target="_blank">
                        How to make a Minecraft server?
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/c/minecraft/videos" target="_blank">
                        Official Minecraft YouTube Channel
                    </a>
                </li>
                <li>
                    <a href="https://www.minecraft.net/en-us/store/minecraft-java-edition" target="_blank">
                        Buy Minecraft Java Edition
                    </a>
                </li>
                <li>
                    <a href="https://play.google.com/store/apps/details?id=com.mojang.minecraftpe" target="_blank">
                        Minecraft on Google Play
                    </a>
                </li>
                <li>
                    <a href="https://www.reddit.com/r/Minecraft/" target="_blank">
                        Official Minecraft Sub Reddit
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/minecraft" target="_blank">
                        Official Minecraft Twitter Account
                    </a>
                </li>
                <li>
                    <a href="https://apps.apple.com/us/app/minecraft/id479516143" target="_blank">
                        Get Minecraft for iOS
                    </a>
                </li>
                <li>
                    <a href="https://skinsmc.org/" target="_blank">
                        Minecraft Skins
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer-links-col">
            <h4>MINECRAFT SERVER TYPES</h4>
            <ul>
                <li>
                    <a href="/server-tags/Prison">Prison Servers</a>
                </li>
                <li>
                    <a href="/server-tags/OP-Prison">OP Prison Servers</a>
                </li>
                <li>
                    <a href="/server-tags/PVP">PvP Servers</a>
                </li>
                <li>
                    <a href="/server-tags/YouTuber">YouTuber Servers</a>
                </li>
                <li>
                    <a href="/server-tags/Parkour">Parkour Servers</a>
                </li>
                <li>
                    <a href="/server-tags/Bedwars">Bedwars Servers</a>
                </li>
                <li>
                    <a href="/server-tags/School">School Servers</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped lang="scss">
.footer-links-row {
    display: flex;
    justify-content: space-around;

    .footer-links-col {
        h4 {
            border-bottom: 1px solid #ccc;
            margin-bottom: 0;
            color: rgb(51, 51, 51);
            min-height: 1.3em;
        }

        ul {
            list-style-type: none;
            margin: 0.5em 0 0;
            padding: 0em 1.5em;

            li {
                min-height: 1.4em;

                a {
                    font-size: 0.75rem;
                    color: #06c;
                    text-decoration: none;
                    min-height: 1.4em;

                    &:hover {
                        color: #23527c;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;

        .footer-links-col {
            margin-bottom: 1em;
        }
    }
}

.divider {
    margin-top: 2em;
    border: 1px dashed gray;
}
</style>
