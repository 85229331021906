import {useUser} from "~/composables/useUser.js";

export default defineNuxtPlugin(async (nuxtApp) => {
    const session = useCookie("session");

    const {refreshUser} = useUser();

    setTimeout(async () => {
        if (session.value) {
            await refreshUser();
        }
    }, 500);
});
