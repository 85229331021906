import { useTippy } from 'vue-tippy';

export function titleEncode(url_title, custom_title = null) {
    if (custom_title) {
        return custom_title;
    }
    if (!url_title) {
        return "";
    }
    return url_title.replace(/[^a-zA-Z0-9\_\.]/g, "");
}

export function getTagURLName(tag) {
    return tag
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
}

export function escapeRegExp(string) {
    return string.replaceAll(/[-[\]{}()*+!<=:?.\/^$|#\s,]/g, '\\$&'); // $& means the whole matched string
}

export function tagsPreventSubmit(e) {
    // if enter is pressed, don't submit
    if (e.key === "Enter" || e.keyCode === 13) {
        e.preventDefault();
        return false;
    }
}

export function sanitizeBanner(banner_url) {
    if (!banner_url) {
        return banner_url;
    }
    if (banner_url.indexOf('/api') !== -1) {
        banner_url = banner_url.replace('/api', '')
    }
    banner_url = banner_url
        .replace('/banner/', '/banners/')
        .replace('www.minecraftiplist.com', 'static.minecraftiplist.com')
    if (banner_url.indexOf('minecraftiplist.com') === -1) {
        banner_url = 'https://static.minecraftiplist.com' + banner_url;
    }

    return banner_url;
}

export async function copyIP(e) {
    if (!import.meta.client) return;
    let element = e.srcElement;

    if (!element.classList.contains("ip-chip")) {
        element = element.closest(".ip-chip");
    }
    const text = element.innerText;
    $fetch("/api/ipcopy/" + text, {
        method: "POST",
    });

    navigator.clipboard.writeText(text).then(() => {
        element = element.querySelector("button");

        const tippyInstance = useTippy(element, {
            content: "Copied to clipboard!",
            trigger: "click",
            onShow(instance) {
                setTimeout(() => {
                    instance.hide();
                }, 1000);
            },
        });

        tippyInstance.show();
    }).catch((error) => {
        element = element.querySelector("button");

        const tippyInstance = useTippy(element, {
            content: "Failed to copy to clipboard!",
            trigger: "click",
            onShow(instance) {
                setTimeout(() => {
                    instance.hide();
                }, 1000);
            },
        });

        tippyInstance.show();
    });
}