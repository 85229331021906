export function useUser() {
    const user = useState("user", () => null);

    const setUser = (userData) => {
        if (userData) {
            user.value = userData;
            useCookie("loggedIn").value = true;
            useCookie("username").value = user.value.username;
        } else {
            user.value = null;
            useCookie("loggedIn").value = false;
            useCookie("username").value = null;
            useCookie("session", null);
        }
    };

    const logout = async () => {
        try {
            await $fetch("/api/user/logout", {
                method: "POST",
                credentials: "include",
            });
        } catch (e) {
        }
        setUser(null);
    };

    const refreshUser = async (session) => {
        try {
            const headers = session ? {"Cookie": "session=" + session} : {};

            $fetch("/api/user", {
                headers,
            }).then((data) => {
                if (data) {
                    setUser(data);
                }
            });
        } catch (e) {
        }
    };

    return {
        user,
        setUser,
        logout,
        refreshUser,
    };
}
