import VueFathom from "@ubclaunchpad/vue-fathom";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueFathom, {
        siteID: "RXHCVXQL",
        settings: {
            spa: "history",
            includedDomains: ["minecraftiplist.com", "www.minecraftiplist.com"]
        },
    });
});
